/*!
 * Copyright 2019, FIX Protocol Ltd.
 */

import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./components/App";

const rootNode = document.getElementById('root');
ReactDOM.render(<App />, rootNode);